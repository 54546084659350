import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Modal, Radio, List, Card, Tooltip, InputNumber, DatePicker, Typography, Divider, Row, Col, Tag, Table, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { decisionMakers, countries, industries, company_sizes } from '../HomePage/CompanyTargetOptions';

const { TextArea } = Input;
const { Title, Paragraph } = Typography;
const { Option } = Select;

const DynamicQuestionForm = ({ form, visible }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [addQuestionModalVisible, setAddQuestionModalVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const handleQuestionEdit = (values) => {
    let updatedQuestions = form.getFieldValue('keyQuestions').map(question => {
      if (question.question_id === currentQuestion.question_id) {
        // Only update the 'question' and 'tag' fields from the form values
        return {
          question_id: currentQuestion.question_id,
          question: values.question, // Ensure this is the field name in your form
          tag: values.tag           // Ensure this is the field name in your form
        };
      }
      return question;
    });
  
    form.setFieldsValue({ keyQuestions: updatedQuestions });
    setModalVisible(false);
  };

  const handleQuestionClick = (question) => {
    setCurrentQuestion(question);
    setModalVisible(true);
    form.setFieldsValue({ question: question.question, tag: question.tag });
  };

  const addNewQuestion = (values) => {
    if (!values.question || !values.tag) {
      return;
    }    
    const existingQuestions = form.getFieldValue('keyQuestions') || [];
    const nextQuestionId = existingQuestions.length > 0 ? Math.max(...existingQuestions.map(q => q.question_id)) + 1 : 1;
    const newQuestion = {
      question: values.question,
      tag: values.tag,
      question_id: nextQuestionId
    };

    const updatedQuestions = [...existingQuestions, newQuestion];
    form.setFieldsValue({ keyQuestions: updatedQuestions });
    setAddQuestionModalVisible(false);
  };

  const renderQuestionModal = () => (
    <Modal
      title="Edit Question"
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      onOk={() => handleQuestionEdit(form.getFieldValue())}
    >
      <Form form={form} layout="vertical" name="editQuestionForm">
        <Form.Item name="question" label="Question" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <p style={{ marginTop: '16px' }}>
          <strong>Preferred:</strong> This question will be researched.
        </p>
        <p>
          <strong>Required:</strong> Essential, no results will be shown if not met.
        </p>
        <p>
          <strong>Pending:</strong> Will not be researched.
        </p>
        <Form.Item name="tag" label="Tag" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio.Button value="PREFERRED">Preferred</Radio.Button>
            <Radio.Button value="REQUIRED">Required</Radio.Button>
            <Radio.Button value="PENDING">Pending</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  const renderAddQuestionModal = () => (
    <Modal
      title="Add Custom Question"
      visible={addQuestionModalVisible}
      onCancel={() => setAddQuestionModalVisible(false)}
      onOk={() => {
        addNewQuestion(form.getFieldValue());
      }}
    >
      <Form form={form} layout="vertical" name="addQuestionForm">
        <Form.Item name="question" label="Question" >
          <Input />
        </Form.Item>
        <Form.Item name="tag" label="Tag">
          <Radio.Group>
            <Radio.Button value="PREFERRED">Preferred</Radio.Button>
            <Radio.Button value="REQUIRED">Required</Radio.Button>
            <Radio.Button value="PENDING">Pending</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  const columns = [
    {
      title: 'Suggested Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
      render: tag => (
        <Tag color={tag === "PREFERRED" ? "green" : tag === "REQUIRED" ? "red" : "geekblue"}>{tag}</Tag>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => handleQuestionClick(record)}>Edit</Button>
      ),
    }
  ];

  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      {renderQuestionModal()}
      {renderAddQuestionModal()}
      <Row justify="space-between" align="middle">
        <Col>
          <h2>Customized Research</h2>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setAddQuestionModalVisible(true)}>+ Custom Questions</Button>
        </Col>
      </Row>
      <Table
        dataSource={form.getFieldValue('keyQuestions') || []}
        columns={columns}
        rowKey="question_id"
        pagination={false}
      />
    </div>
  );
};
const DynamicPeopleQuestionForm = ({ form, mode, visible }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [addQuestionModalVisible, setAddQuestionModalVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const handleQuestionEdit = (values) => {
    let updatedQuestions = form.getFieldValue('contactKeyQuestions').map(question => {
      if (question.question_id === currentQuestion.question_id) {
        return {
          question_id: currentQuestion.question_id,
          question: values.question, // Ensure this is the field name in your form
          tag: values.tag           // Ensure this is the field name in your form
        };
      }
      return question;
    });
    form.setFieldsValue({ contactKeyQuestions: updatedQuestions });
    setModalVisible(false);
  };

  const handleQuestionClick = (question) => {
    setCurrentQuestion(question);
    setModalVisible(true);
    form.setFieldsValue({ question: question.question, tag: question.tag });
  };

  const addNewQuestion = (values) => {
    const existingQuestions = form.getFieldValue('contactKeyQuestions') || [];
    const nextQuestionId = existingQuestions.length > 0 ? Math.max(...existingQuestions.map(q => q.question_id)) + 1 : 1;
    const newQuestion = {
      question: values.question,
      tag: values.tag,
      question_id: nextQuestionId
    };

    const updatedQuestions = [...existingQuestions, newQuestion];
    form.setFieldsValue({ contactKeyQuestions: updatedQuestions });
    setAddQuestionModalVisible(false);
  };

  const renderQuestionModal = () => (
    <Modal
      title="Edit Question"
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      onOk={() => handleQuestionEdit(form.getFieldValue())}
    >
      <Form form={form} layout="vertical" name="editQuestionForm">
        <Form.Item name="question" label="Question" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <p style={{ marginTop: '16px' }}>
          <strong>Preferred:</strong> This question will be researched.
        </p>
        <p>
          <strong>Required:</strong> Essential, no results will be shown if not met.
        </p>
        <p>
          <strong>Pending:</strong> Will not be researched.
        </p>
        <Form.Item name="tag" label="Tag" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio.Button value="PREFERRED">Preferred</Radio.Button>
            <Radio.Button value="REQUIRED">Required</Radio.Button>
            <Radio.Button value="PENDING">Pending</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  const renderAddQuestionModal = () => (
    <Modal
      title="Add Custom Question"
      visible={addQuestionModalVisible}
      onCancel={() => setAddQuestionModalVisible(false)}
      onOk={() => {
        addNewQuestion(form.getFieldValue());
      }}
    >
      <Form form={form} layout="vertical" name="addQuestionForm">
        <Form.Item name="question" label="Question" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="tag" label="Tag" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio.Button value="PREFERRED">Preferred</Radio.Button>
            <Radio.Button value="REQUIRED">Required</Radio.Button>
            <Radio.Button value="PENDING">Pending</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  const columns = [
    {
      title: 'Suggested Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
      render: tag => (
        <Tag color={tag === "PREFERRED" ? "green" : tag === "REQUIRED" ? "red" : "geekblue"}>{tag}</Tag>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => handleQuestionClick(record)}>Edit</Button>
      ),
    }
  ];

  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      {renderQuestionModal()}
      {renderAddQuestionModal()}
      <Form.Item
          name="contactPreference"
          label="Contact Preference"
          rules={[{ required: true, message: "Please provide who you wish to connect" }]}
        >
          <Select
            mode="tags"
            allowClear
            placeholder="Select contact preference"
            options={decisionMakers}
            disabled={!mode}
          />
        </Form.Item>       
      <Row justify="space-between" align="middle">
        <Col>
          <h2>Customized Research</h2>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setAddQuestionModalVisible(true)}>+ Custom Questions</Button>
        </Col>
      </Row>
      <Table
        dataSource={form.getFieldValue('contactKeyQuestions') || []}
        columns={columns}
        rowKey="question_id"
        pagination={false}
      />
    </div>
  );
};


// Step 1: Service Panel
const ServicePanelForm = ({ form, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <Form.Item
      name="name"
      label="Campaign name"
      rules={[{ required: true, message: "Name this campaign" }]}
    >
      <TextArea autoSize placeholder="" />
    </Form.Item>

    <Title level={4}>
        Service my company provides:
    </Title>
    <Card style={{ marginBottom: 16 }}>
      <Form.Item
        name={['services', 'serviceName']}
        label="Service Name"
        rules={[{ required: true, message: "Please provide the service name" }]}
      >
        <Input placeholder="E.g CRM Consulting" />
      </Form.Item>
      <Form.Item
        name={['services', 'serviceDescription']}
        label="Service Description"
        rules={[{ required: true, message: "Please provide the service description" }]}
      >
        <TextArea placeholder="Provide CRM consulting to SME companies" />
      </Form.Item>
    </Card>
  </div>
);

// Step 2: Company Criteria
const CompanyCriteriaForm = ({ form, mode, visible }) => {
    const countries_options = countries.map(item => ({ value: item }));
    const industries_options = industries.map(item => ({ value: item }));
    const company_size_options = company_sizes.map(item => ({ value: item }));
    const filterOption = (input, option) =>
      (option?.value ?? '').toLowerCase().includes(input.toLowerCase()); 

      return (
        <div style={{ display: visible ? 'block' : 'none' }}>
        <Form.Item
          label="Target Company's Size"
          rules={[{ required: true, message: "Please provide your target company's size" }]}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8 }}>From</span>
            <Form.Item
              name="minSize"
              rules={[{ required: true, message: 'Please input the minimum size!' }]}
              style={{ margin: 0, marginRight: 16 }}
              validateStatus={form.getFieldError('minSize').length ? 'error' : ''}
              help=""
            >
              <InputNumber min={1} placeholder="Min" style={{ width: '100%' }} disabled={!mode} />
            </Form.Item>
            <span style={{ marginRight: 8 }}>to</span>
            <Form.Item
              name="maxSize"
              rules={[{ required: true, message: 'Please input the maximum size!' }]}
              style={{ margin: 0 }}
              validateStatus={form.getFieldError('maxSize').length ? 'error' : ''}
              help=""
            >
              <InputNumber min={1} placeholder="Max" style={{ width: '100%' }} disabled={!mode} />
            </Form.Item>
          </div>
          <Form.ErrorList errors={form.getFieldError('minSize').concat(form.getFieldError('maxSize'))} />
        </Form.Item>
        <Form.Item
          name="location"
          label="Location"
          rules={[{ required: true, message: "Please provide the location of your target company" }]}
        >
          <Select
            placeholder="Select location"
            mode="tags"
            allowClear
            optionFilterProp="children"
            filterOption={filterOption}
            options={countries_options}
            disabled={!mode}
          />
        </Form.Item>
        <Form.Item
          name="industry"
          label="Industry"
          rules={[{ required: true, message: "Please provide the industry of your target company" }]}
        >
          <Select
            placeholder="Select industry"
            mode="multiple"
            allowClear
            filterOption={filterOption}
            options={industries_options}
            disabled={!mode}
          />
        </Form.Item>

        {/* <Form.Item
          name="keywords"
          label="Keywords"
        >
          <TextArea autoSize placeholder="" disabled={!mode} />
        </Form.Item> */}
        {/* <Form.Item
          label="Founded Year"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8 }}>From</span>
            <Form.Item
              name="foundedYear"
              style={{ margin: 0, marginRight: 16 }}
              validateStatus={form.getFieldError('foundedYear').length ? 'error' : ''}
              help=""
            >
              <Select placeholder="Select Year" style={{ width: '100%' }} allowClear disabled={!mode}>
                {Array.from({ length: 2024 - 2014 + 1 }, (_, i) => (
                  <Option key={2014 + i} value={2014 + i}>
                    {2014 + i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          name="needFunding"
          label="Does the company need to be funded?"
          rules={[{ required: true, message: "Please select if the company needs to be funded" }]}
        >
          <Select placeholder="Select an option" disabled={!mode}>
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) => prevValues.needFunding !== currentValues.needFunding}
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('needFunding') === 'yes' ? (
              <>
                <Form.Item
                  name="lastFundingDate"
                  label={
                    <>
                      Last Funding Date Greater than 
                      <Tooltip title="If set, we will filter companies that have been funded later than the date set.">
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                    </>
                  }
                >
                  <DatePicker disabled={!mode} />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item> */}
    </div>
  );        
}

// Step 3: Company Insights
const CompanyInsightsForm = ({ form, mode, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    {/* The company is PREFERRED if */}

    <Form.List name={['keyQuestions', 'preferred']}>
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The company is PREFERRED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>         
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                label={`Prefer Statement ${index + 1}`}
                rules={[{ required: true, message: "Please provide a statement" }]}
              >
                <Input placeholder={`E.g They have more than ${index + 1} engineers`} disabled={!mode} />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>

    <Divider />

    {/* The company is FILTERED if */}

    <Form.List name={['keyQuestions', 'filtered']}>
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The company is FILTERED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>    
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                label={`Filter Statement ${index + 1}`}
              >
                <Input placeholder={`E.g They are using salesforce`} disabled={!mode} />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>

    <Divider />

    {/* More information I'd like to learn about company */}

    <Form.List name={['keyQuestions', 'moreInfo']}>
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                More information I'd like to learn about the company:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>         
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                label={`More Info Statement ${index + 1}`}
              >
                <Input placeholder={`E.g What are the company's growth plans?`} disabled={!mode} />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>
  </div>
);


const ContactInsightsForm = ({ form, mode, visible }) => (
    <div style={{ display: visible ? 'block' : 'none' }}>
      {/* The contact is PREFERRED if */}  
      <Form.List name={['contactKeyQuestions', 'preferred']}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The contact is PREFERRED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>           
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`Prefer Statement ${index + 1}`}
                  rules={[{ required: false, message: "Please provide a statement" }]}
                >
                  <Input placeholder={`e.g They are decision makers`} disabled={!mode} />
                </Form.Item>
                {fields.length > 1 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>
  
      <Divider />
  
  
      <Form.List name={['contactKeyQuestions', 'filtered']}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The contact is FILTERED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>           
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`Filter Statement ${index + 1}`}
                >
                  <Input placeholder={`e.g They have more than 10 years experience`} disabled={!mode} />
                </Form.Item>
                {fields.length > 1 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>
  
      <Divider />
  
      {/* More information I'd like to learn about contact */}
  
      <Form.List name={['contactKeyQuestions', 'moreInfo']}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                    More information I'd like to learn about the contact:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>           
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`More Info Statement ${index + 1}`}
                >
                  <Input placeholder={`E.g What is this person's influence on buying decisions?`} disabled={!mode} />
                </Form.Item>
                {fields.length > 1 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
    
  

// Step 5: Email Setup
const EmailSetupForm = ({ form, mode, visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
  <div style={{ display: visible ? 'block' : 'none' }}>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={showModal}>
            Email Examples
          </Button>
        </Col>
      </Row>
      <Modal title="Email Examples" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={960}>
        <Row gutter={16}>
          <Col span={12}>
            <Title level={4}>Template Structure:</Title>
            <Paragraph><strong>Subject:</strong> [Template Email Subject]</Paragraph>
            <Paragraph>Hey [Contact Name],</Paragraph>
            <Paragraph>[Dealight's Research] + [Template Value Propositions OR Template Customer Pain Points OR Template Fun Facts]</Paragraph>


            <Paragraph>[Template Company Introduction]</Paragraph>
            <Paragraph>[Template Call to Action]</Paragraph>
            <Paragraph>Best regards,</Paragraph>
            <Paragraph>[Sender Name]</Paragraph>
            <Paragraph>[Sender Job Title]</Paragraph>
          </Col>
          <Col span={12}>
            <Title level={4}>Example Message:</Title>
            <Paragraph><strong>Subject:</strong> Hey John, interested in getting more clients?</Paragraph>
            <Paragraph>Hey John,</Paragraph>
            <Paragraph>I've noticed Worca is actively hiring a BDR manager, so I figure you may be interested in scaling your current outbound sales pipeline.</Paragraph>
            <Paragraph>At DealightAI, We specializes in finding companies showing initial buying interests to your services. So you'll oftentime have the edge of being the first person to contact them.</Paragraph>
            <Paragraph>If you're intersted, let's perhaps schedule a quick call to discuss more about it?</Paragraph>
            <Paragraph>Best regards,</Paragraph>
            <Paragraph>Ryan</Paragraph>
            <Paragraph>CEO at Dealight</Paragraph>
          </Col>
        </Row>
      </Modal> 

      <Form.Item
      name="emailTemplate"
      label="Template Email"
    >
      <Input.TextArea placeholder="Full email message" disabled={!mode} />
    </Form.Item>      
    <Form.Item
      name="emailTemplateSubject"
      label="Template Email Subject"
    >
      <Input placeholder="E.g., Hey [name], Interested in learning how Dealight works?" disabled={!mode} />
    </Form.Item>
    <Form.Item
      name="emailTemplateName"
      label="Sender Name"
    >
      <Input placeholder="Name to show up in email" disabled={!mode} />
    </Form.Item>
    <Form.Item
      name="emailTemplateTitle"
      label="Sender Job Title"
    >
      <Input placeholder="Job Title to show up in email" disabled={!mode} />
    </Form.Item>

    <Form.Item
      name="emailCallToAction"
      label="Template Call to Action"
    >
      <Input.TextArea placeholder="Would you like to schedule a demo with us?" disabled={!mode} />
    </Form.Item>    

    <Form.List
      name="emailTemplateValueProp"
    >
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                Template Value Propositions:
                </Title>
            </Col>
            <Col>
            {fields.length < 3 ? (
              <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop:10}}>
                Add Value Proposition
              </Button>
            ) : null}
            </Col>
            </Row>          
          <Form.Item label="">
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Card key={key} style={{ marginBottom: 8 }}>
                <Form.Item
                  {...restField}
                  name={[name]}
                  fieldKey={[fieldKey]}
                >
                  <Input.TextArea placeholder="Value proposition in the outreach message" disabled={!mode} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Card>
            ))}
          </Form.Item>
        </>
      )}
    </Form.List>

    <Form.List
      name="emailTemplateCompanyIntroduction"
    >
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                Template Company Introdcution:
                </Title>
            </Col>
            <Col>
            {fields.length < 3 ? (
              <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop:10}}>
                Add Company Introdcution
              </Button>
            ) : null}
            </Col>
            </Row>          
          <Form.Item label="">
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Card key={key} style={{ marginBottom: 8 }}>
                <Form.Item
                  {...restField}
                  name={[name]}
                  fieldKey={[fieldKey]}
                >
                  <Input.TextArea placeholder="Company Intro in the outreach message" disabled={!mode} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Card>
            ))}
          </Form.Item>
        </>
      )}
    </Form.List>    

    <Form.List
      name="emailTemplateFunFacts"
    >      
      {(fields, { add, remove }) => (
        <>
        <Row justify="space-between" align="middle">
        <Col>
            <Title level={4}>
            Template Fun Facts:
            </Title>
        </Col>
        <Col>
        {fields.length < 3 ? (
          <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop:10}}>
            Add Fun Facts
          </Button>
        ) : null}
        </Col>
        </Row>        
          <Form.Item label="">
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Card key={key} style={{ marginBottom: 8 }} >
                <Form.Item
                  {...restField}
                  name={[name]}
                  fieldKey={[fieldKey]}
                >
                  <Input.TextArea placeholder="Fun fact about the company/product" disabled={!mode} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Card>
            ))}
          </Form.Item>
        </>
      )}
    </Form.List>

    <Form.List
      name="emailTemplateCustomerPainPoints"
    >       
      {(fields, { add, remove }) => (
        <>
        <Row justify="space-between" align="middle">
        <Col>
            <Title level={4}>
            Template Customer Pain Points:
            </Title>
        </Col>
        <Col>
        {fields.length < 3 ? (
          <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop:10}}>
            Add Customer Pain Points
          </Button>
        ) : null}
        </Col>
        </Row>         
          <Form.Item label="">
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Card key={key} style={{ marginBottom: 8 }}>
                <Form.Item
                  {...restField}
                  name={[name]}
                  fieldKey={[fieldKey]}
                >
                  <Input.TextArea placeholder="Customer pain point that the product/service addresses" disabled={!mode} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Card>
            ))}
          </Form.Item>
        </>
      )}
    </Form.List>
  </div>
);
}

const LeadsSetupForm = ({ form, mode, currentStep, companyQuestions, peopleQuestions }) => {
  return (
    <>
      <ServicePanelForm form={form} mode={mode} visible={currentStep === 0} />
      <CompanyCriteriaForm form={form} mode={mode} visible={currentStep === 1} />
      <DynamicQuestionForm form={form} visible={currentStep === 1} />
      <DynamicPeopleQuestionForm form={form} mode={mode} visible={currentStep === 2} />

      {/* <CompanyInsightsForm form={form} mode={mode} visible={currentStep === 5} /> */}
      {/* <ContactInsightsForm form={form} mode={mode} visible={currentStep === 2} /> */}
      <EmailSetupForm form={form} mode={mode} visible={currentStep === 3} />
    </>
  );
};

export default LeadsSetupForm;
