import React, { useState } from "react";
import { Modal, Button, DatePicker, Typography, Space, Divider } from "antd";
import useDocumentPageHook from "../DocumentPage/DocumentPageHook";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Text, Paragraph } = Typography;


const ExportCSVModal = ({ sessionId, visible, onClose }) => {
  const [filterOption, setFilterOption] = useState("all");
  const [dateRange, setDateRange] = useState([]);
  const { exportCSV } = useDocumentPageHook();

  const handleExport = async () => {
    let startDate = null;
    let endDate = null;

    if (filterOption === "range" && dateRange?.length === 2) {
      startDate = dayjs(dateRange[0]).toISOString();
      endDate = dayjs(dateRange[1]).toISOString();
    }
    await exportCSV(sessionId, filterOption, startDate, endDate);
  };

  const handleFilterSelect = (option) => {
    setFilterOption(option);
    if (option !== "range") {
      setDateRange([]); // Clear date range when switching to non-range options
    }
  };

  const onExport = () => {
    const startDate = filterOption === "range" && dateRange?.length === 2 ? dayjs(dateRange[0]).toISOString() : null;
    const endDate = filterOption === "range" && dateRange?.length === 2 ? dayjs(dateRange[1]).toISOString() : null;
    handleExport(filterOption, startDate, endDate);
  };

  return (
    <Modal
      title={<Text strong style={{ fontSize: "18px" }}>Export CSV Data</Text>}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="export" type="primary" onClick={onExport} disabled={filterOption === "range" && dateRange?.length !== 2}>
          Export
        </Button>,
      ]}
    >
      <Paragraph>
        Select the type of data you want to export and click <Text strong>Export</Text>. You can export data for today, all available data, or specify a custom date range.
      </Paragraph>

      <Space direction="vertical" style={{ width: "100%" }}>
        <Button.Group style={{ width: "100%" }}>
          <Button
            type={filterOption === "24h" ? "primary" : "default"}
            onClick={() => handleFilterSelect("24h")}
            style={{ width: "33%" }}
          >
            Today
          </Button>
          <Button
            type={filterOption === "all" ? "primary" : "default"}
            onClick={() => handleFilterSelect("all")}
            style={{ width: "33%" }}
          >
            All
          </Button>
          <Button
            type={filterOption === "range" ? "primary" : "default"}
            onClick={() => handleFilterSelect("range")}
            style={{ width: "33%" }}
          >
            Custom Range
          </Button>
        </Button.Group>

        {filterOption === "range" && (
          <>
            <Divider style={{ margin: "16px 0" }} />
            <RangePicker
              style={{ width: "100%" }}
              onChange={(dates) => setDateRange(dates)}
              placeholder={["Start Date", "End Date"]}
            />
          </>
        )}
      </Space>
    </Modal>
  );  
};

export default ExportCSVModal;
