import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Divider, Modal, List, Spin } from 'antd';
import { UploadOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

import ImportCSVModal from '../LeadsPage/ImportCSVModal';

const SetupOverview = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const { dailyGenerationQuota, generationCredit, qualificationCredit, maxCampaigns, initializedCampaign, fetchUserProfile } = useProfilePageHook();
  const { getCampaignList, loadCampaign } = useDocumentPageHook();
  const [showModal, setShowModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleGenerateLeadsClick = () => {
    navigate('/campaign/setup/list'); // Replace with your specific route
  };

  const handleCSVSubmit = (data, uploadedFileName) => {
    setFileName(uploadedFileName);
    navigate('/campaign/setup/import', { state: { data, fileName: uploadedFileName } });
  };

  const handleEditClick = () => {
    setShowModal(true);
  };

  const showLoadModal = async () => {
    setIsModalVisible(true);
    setLoading(true)
    const campaigns = await getCampaignList(); // Fetch campaigns when the modal is shown
    setCampaigns(campaigns)
    setLoading(false)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCampaignSelect = async (campaign) => {
    await loadCampaign(campaign.id)
    handleGenerateLeadsClick();
    setIsModalVisible(false);
  };  

  // Determine color based on initializedCampaign vs genericCredit
  const campaignTextColor = initializedCampaign >= maxCampaigns ? 'red' : 'green';

  return (
    <Row gutter={16} style={{ justifyContent: 'center', padding: '20px' }}>
      <Col span={10}>
        <Card bordered={false} style={{ textAlign: 'center' }}>
          <OrderedListOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
          <h2>Generate Leads</h2>
          <p>Automatically generate potential leads based on your criteria.</p>
          <Button
            type="primary"
            onClick={handleGenerateLeadsClick}
            disabled={initializedCampaign >= maxCampaigns || generationCredit == 0}  // Disable button if condition is met
          >
            Generate Leads           
          </Button>
          <Button type="default" onClick={() => showLoadModal()} style={{marginLeft:10}} disabled={initializedCampaign >= maxCampaigns || generationCredit == 0}>
            Load Campaign
          </Button>        
          <p style={{ color: campaignTextColor }}>
            Existing Campaigns: {initializedCampaign} / {maxCampaigns}
          </p>              
          <Divider />
          <p>
            Generation Credits: {generationCredit}
          </p>
        </Card>
      </Col>

      <Col span={10}>
        <Card bordered={false} style={{ textAlign: 'center' }}>
          <UploadOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
          <h2>Import Leads</h2>
          <p>
            You can import up to 100,000 rows at a time. Select CSV File to see more details.
          </p>
          <Button type="primary" disabled={initializedCampaign >= maxCampaigns} onClick={handleEditClick}>Select CSV File</Button>
          <ImportCSVModal onSubmit={handleCSVSubmit} visible={showModal} setVisible={setShowModal} />
            <p style={{ color: campaignTextColor }}>
            Existing Campaigns: {initializedCampaign} / {maxCampaigns}
          </p>  
          <Divider />
          <p>Qualification Credits: {qualificationCredit}</p>
        </Card>
      </Col>
      <Modal
        title="Select a Campaign"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
<List
  dataSource={loading ? [] : campaigns} // Show an empty list if loading
  loading={false} // Prevent List's own loading spinner to avoid conflict
  renderItem={(campaign) => (
    <List.Item
      actions={[
        <Button
          type="link"
          onClick={() => handleCampaignSelect(campaign)}
        >
          Select
        </Button>,
      ]}
    >
        <List.Item.Meta
          title={campaign.name}
        />
      </List.Item>
    )}
    >
      {loading && (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <Spin size="large" />
        </div>
      )}
    </List>        
      </Modal>      
    </Row>
    
  );
};

export default SetupOverview;
