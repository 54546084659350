import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Statistic, Typography, Divider, Tooltip, Row, Col, InputNumber } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import Notification from '../Notification/NotificationMessage';

const NewTaskModal = ({ onInitiateTask, maxURLCount, isProcessing, outOfCredit, dailyQuota, modalVisible, setModalVisible }) => {
  const navigate = useNavigate();
  const { getCampaignOverview, getBusinessContext } = useDocumentPageHook();
  const [urlCount, setUrlCount] = useState(5);
  const [isInitiating, setIsInitiating] = useState(false);
  const [totalUrlsToAnalyze, setTotalUrlsToAnalyze] = useState('Loading...');
  const [campaignId, setCampaignId] = useState(null);
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false); // New state


  useEffect(() => {
    if (modalVisible) {
      fetchBusinessContext();
      setUrlCount(5); // Reset to default when modal opens
    }
  }, [modalVisible]);

  const fetchCampaignOverview = async (campaignId) => {
    setTotalUrlsToAnalyze('Loading...');
    setIsCampaignLoaded(false); // Disable the button while loading
    try {
      const data = await getCampaignOverview(campaignId);
      if (data && data.total_urls_to_process !== undefined) {
        setTotalUrlsToAnalyze(data.total_urls_to_process);
      } else {
        Notification.error('Failed to load campaign overview. Try again later');
      }
    } catch (error) {
      Notification.error('Error loading campaign overview.');
    } finally {
      setIsCampaignLoaded(true); // Enable the button after loading
    }
  };

  const fetchBusinessContext = async () => {
    const contextData = await getBusinessContext();
    if (contextData && contextData.campaign && contextData.campaign.id) {
      setCampaignId(contextData.campaign.id);
      fetchCampaignOverview(contextData.campaign.id);
    } else {
      Notification.error('Failed to load business context. Try again later');
    }
  };

  const handleInitiate = async () => {
    setIsInitiating(true);
    await onInitiateTask(urlCount);
    setIsInitiating(false);
    setModalVisible(false); // Close the modal on successful initiation
    navigate('/campaign/tasks');
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const incrementUrlCount = () => {
    if (urlCount < dailyQuota) {
      setUrlCount(urlCount + 1);
    }
  };

  const decrementUrlCount = () => {
    if (urlCount > 0) {
      setUrlCount(urlCount - 1);
    }
  };

  const handleInputChange = (value) => {
    if (value >= 0 && value <= dailyQuota) {
      setUrlCount(value);
    }
  };

  return (
    <Modal
      title="Initiate Task"
      open={modalVisible}
      onCancel={handleModalClose}
      footer={[
        <Button key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button
          key="run"
          type="primary"
          onClick={handleInitiate}
          disabled={isInitiating || totalUrlsToAnalyze === 0 || !isCampaignLoaded}
        >
          Initiate Analysis
        </Button>,
      ]}
    >
      <Card bordered={false} style={{ width: '100%' }}>
        <Row gutter={[16, 16]} align="middle">
          {/* Potential Leads to Analyze */}
          <Col span={12}>
            <Tooltip title="The total number of potential leads available for analysis.">
              <div style={{alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography.Text strong>Potential Leads to Analyze:</Typography.Text>
              <Statistic value={totalUrlsToAnalyze} style={{marginTop:10}} />
              </div>
            </Tooltip>
          </Col>

          {/* Daily Analysis Quota */}
          <Col span={12}>
            <Tooltip title="Your daily limit for relevant leads researched.">
              <div style={{alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography.Text strong>Daily Analysis Quota:</Typography.Text>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:10 }}>
                  <Button
                    icon={<MinusOutlined />}
                    onClick={decrementUrlCount}
                    disabled={urlCount <= 1}
                    style={{ marginRight: 8 }}
                  />
                  <InputNumber
                    min={0}
                    max={dailyQuota}
                    value={urlCount}
                    readOnly
                    onChange={handleInputChange}
                    style={{ margin: '0 8px', width: 100 }}
                  />
                  <Button
                    icon={<PlusOutlined />}
                    onClick={incrementUrlCount}
                    disabled={urlCount >= dailyQuota}
                  />
                </div>
              </div>
            </Tooltip>
          </Col>
        </Row>
      </Card>

    </Modal>
  );
};

export default NewTaskModal;
