import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Image, message, Space, Tag, Row, Col } from "antd";
import { LinkedinOutlined, InfoCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';

import useDocumentPageHook from "../DocumentPage/DocumentPageHook";
import AddTrackingModal from "./AddTrackingModal";
import DeleteWithModal from './DeleteModal';
import TrackingDetailDrawer from './TrackingDetailDrawer';
import Paragraph from "antd/es/skeleton/Paragraph";


const TrackingPage = () => {
  const { getSystemSessions, getSystemTasks, initiateTrackingTask, pollTrackingSessions, cleanupSessions, trackingSessions } = useDocumentPageHook();
  const [systemSessions, setSystemSessions] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [sessionDetails, setSessionDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);


  useEffect(() => {
    console.log('here')
    setSystemSessions(trackingSessions);
  }, [trackingSessions]);  

  useEffect(() => {
    fetchSystemSessions();
  }, []);

  const fetchSystemSessions = async () => {
    const sessions = await getSystemSessions("tracking");
    if (sessions) {
      setSystemSessions(sessions);
    }
  };

  const fetchSessionDetails = async (sessionId) => {
    const details = await getSystemTasks(sessionId);
    if (details) {
      setSessionDetails(details.company_details);
    }
  };


  const toggleDrawer = (record) => {
    if (record == selectedLead) {
      if (drawerVisible) {
        setDrawerVisible(false);
      } else{
        setDrawerVisible(true);
      }
    } else {
      setDrawerVisible(true);
    }  
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };    
  const getTagColor = (state) => {
    switch (state) {
      case 'RUNNING':
      case 'PAUSED':
        return 'yellow';
      case 'COMPLETED':
        return 'green';
      case 'DEFAULT':
      case 'FAILED':
      case 'ABORTED':
        return 'red';
      default:
        return 'blue';
    }
  };  

  const handleDelete = async (session_id) => {
    await cleanupSessions(session_id);  // Call the cleanup session function
    fetchSystemSessions();  // Refresh the sessions list

  };  

  const handleRowClick = (record) => {
    setSelectedSessionId(record.session_id);
    fetchSessionDetails(record.session_id);
  };

  const handleAddTrackingTask = async (values) => {
    await initiateTrackingTask(values.url_short_name, values.contactPreference, values.industry, values.location, values.minSize, values.maxSize);
    pollTrackingSessions(); // Refresh system sessions after adding a tracking task
    
    setIsModalVisible(false);
  };

  const columnsSystemSessions = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 50,
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (_, record) => (
          <Tag color={getTagColor(record.session_state)}>
            {record.session_state === "DEFAULT" ? "CONFIGURING" : record.session_state}
          </Tag>
        ),
        width: 50,
      }, 
    {
        title: "# Leads",
        dataIndex: "total_urls_to_process",
        key: "total_urls_to_process",
        render: (_, record) => (
            record?.statistics?.Complete
          ),        
        width: 50,
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
          <Button onClick={() => handleRowClick(record)}>
            Leads
          </Button>
          <DeleteWithModal record={record} handleDelete={handleDelete} />
        </Space>
        ),
        width: 75,

      },          
  ];

  const columnsSessionDetails = [
    {
        title: 'Contact',
        dataIndex: 'logo_url',
        key: 'logo_url',
        render: (text, record) => (
          <Space>
            <Image
              width={35}
              height={35}
              src={record?.company_data?.contact_people[0]?.logo_url || '/person_icon.png'}
              style={{ borderRadius: '50%' }}
              alt="profile"
            />

            {record?.company_data?.contact_people[0]?.name}
          
          </Space>
        ),
      },
      {
        title: 'Job Title',
        dataIndex: 'title',
        key: 'title',
        render: (_, record) => (
            record?.company_data?.contact_people[0]?.title
        ),
        
      },
        {
        title: 'Company',
        key: 'company_name',
        
        render: (_, record) => (
            <Space>
            <Image
                width={40}
                height={40}
                src={record.company_data?.company_logo ? `data:image/jpeg;base64,${record.company_data.company_logo}`: (record.company_data?.company_logo_url || '/person_icon.png')}
                style={{ borderRadius: '50%' }}
                alt="logo"
                onClick={ () => {
                    setSelectedLead(record);
                    toggleDrawer(record);
                }}
            />
            <a 
            style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={ () => {
                setSelectedLead(record);
                toggleDrawer(record);
            }}            
            >
            {record.company_data?.company_name}
            </a>
            </Space>
        ),
        }, 
      {
        title: 'LinkedIn',
        dataIndex: 'url',
        key: 'url',
        render: (_, record) => (
          <a href={record?.company_data?.contact_people[0]?.url} target="_blank" rel="noopener noreferrer">
            <LinkedinOutlined style={{ fontSize: '20px', color: '#0077b5' }} />
          </a>
        )
      },             
      {
          title: 'Email',
          dataIndex: 'email_address',
          key: 'email_address',
          render: (_, record) => (
            record?.company_data?.contact_people[0]?.email_address
        ),
      },    
  ];

  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "16px",
        backgroundColor: "#f9f9f9", // Slightly lighter background for the page
        minHeight: "100vh", // Ensures the page takes full height
      }}
    >
      <Row gutter={[16, 16]}>
        {/* Left Column: Session Details */}
        <Col span={14}>
          <div
            style={{
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              maxHeight: "calc(100vh - 64px)", // Adjust height for responsiveness
              overflow: "hidden", // Prevent content overflow
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row gutter={16} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Col>
                    <h2>Tracking Leads</h2>

                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>          
                <Button type="primary" style={{marginTop:'10px'}}>
                    Export CSV
                </Button>
                </Col>
            </Row>            
            <div
              style={{
                flex: 1,
                overflowX: "auto", // Horizontal scroll for table
                overflowY: "auto", // Vertical scroll if needed
              }}
            >
              <Table
                columns={columnsSessionDetails}
                dataSource={sessionDetails}
                rowKey="name"
                pagination={{ pageSize: 5 }}
            
              />
            </div>
          </div>
        </Col>
  
        {/* Right Column: System Sessions */}
        <Col span={10}>
          <div
            style={{
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              maxHeight: "calc(100vh - 64px)", // Adjust height for responsiveness
              overflow: "hidden", // Prevent content overflow
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row gutter={16} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Col>
                    <h2>Tracking Tasks</h2>

                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>          
                    <Button
                    type="primary"
                    style={{ marginTop:'10px' }}
                    onClick={() => setIsModalVisible(true)}
                    >
                    Add Tracking Task
                    </Button>
                </Col>
            </Row>            
            <div
              style={{
                flex: 1,
                overflowX: "auto", // Horizontal scroll for table
                overflowY: "auto", // Vertical scroll if needed
              }}
            >
              <Table
                columns={columnsSystemSessions}
                dataSource={systemSessions}
                rowKey="id"
                pagination={{ pageSize: 5 }}
              />
            </div>
          </div>
        </Col>
      </Row>
  
      {/* Modal for Adding Tracking Task */}
      <TrackingDetailDrawer visible={drawerVisible} onClose={onDrawerClose} record={selectedLead} />

      <AddTrackingModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onConfirm={handleAddTrackingTask}
      />
    </div>
  );
  
  
};

export default TrackingPage;
