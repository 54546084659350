import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, notification, List, Button, Spin, InputNumber, Row, Col, Divider } from "antd";
import { decisionMakers, countries, industries } from '../HomePage/CompanyTargetOptions';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const AddTrackingModal = ({ visible, onCancel, onConfirm }) => {
  const [form] = Form.useForm();
  const { getCampaignList } = useDocumentPageHook();

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  const countries_options = countries.map(item => ({ value: item }));
  const industries_options = industries.map(item => ({ value: item }));

  const filterOption = (input, option) =>
    (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        setLoading(true);
        const campaigns = await getCampaignList(); // Fetch campaigns when the modal is shown
        setCampaigns(campaigns);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  const isValidLinkedInCompanyUrl = (url) => {
    try {
      if (!url.startsWith('https://') || !url.includes('linkedin.com')) {
        return { isValid: false, companyName: null };
      }

      const parsedUrl = new URL(url);
      const pathParts = parsedUrl.pathname.split('/').filter(Boolean);
      const companyIndex = pathParts.indexOf('company');

      if (companyIndex !== -1 && companyIndex + 1 < pathParts.length) {
        const companyName = pathParts[companyIndex + 1];
        return { isValid: true, companyName };
      }

      return { isValid: false, companyName: null };
    } catch (error) {
      console.error("Invalid URL:", error);
      return { isValid: false, companyName: null };
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const { linkedinURL } = values;
      const { isValid, companyName } = isValidLinkedInCompanyUrl(linkedinURL);

      if (!isValid) {
        notification.error({
          message: "Invalid LinkedIn URL",
          description: "Please provide a valid LinkedIn company URL.",
        });
        return;
      }

      onConfirm({ ...values, url_short_name: companyName });
      form.resetFields();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleCampaignSelect = async (campaign) => {
    const companyCriteria = campaign.company_criteria || {};

    // Populate the form fields based on the campaign data
    form.setFieldsValue({
      industry: companyCriteria.industry || [],
      location: companyCriteria.location || [],
      minSize: companyCriteria.companySize?.min || undefined,
      maxSize: companyCriteria.companySize?.max || undefined,
      contactPreference: campaign.contact_people_criteria?.contact_preferences || [],
    });

    notification.success({
      message: "Campaign Selected",
    });
  };

  return (
    <Modal
      title="Add Tracking Task"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      okText="Confirm"
      cancelText="Cancel"
      width={950} // Slightly wider modal
      bodyStyle={{
        padding: 0, // Remove default padding for better control
      }}
    >
      <Row gutter={16} style={{ padding: 16 }}>
        {/* Left Column: Form */}
        <Col span={14}>
          <div
            style={{
              height: '100%',
              border: '1px solid #f0f0f0',
              borderRadius: 8,
              padding: 8,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              maxHeight: '450px',
              backgroundColor: '#fff',
            }}
          >

            <Form form={form} layout="vertical">
              <Form.Item
                name="linkedinURL"
                label="Company LinkedIn URL"
                rules={[{ required: true, message: "Please enter a Company LinkedIn URL!" }]}
              >
                <Input placeholder="e.g https://www.linkedin.com/company/salesforce/" />
              </Form.Item>
              <Form.Item
                label="Target Company's Size"
                rules={[{ required: true, message: "Please provide your target company's size" }]}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 8 }}>From</span>
                  <Form.Item
                    name="minSize"
                    rules={[{ required: true, message: 'Please input the minimum size!' }]}
                    style={{ margin: 0, marginRight: 16 }}
                  >
                    <InputNumber min={1} placeholder="Min" style={{ width: '100%' }} />
                  </Form.Item>
                  <span style={{ marginRight: 8 }}>to</span>
                  <Form.Item
                    name="maxSize"
                    rules={[{ required: true, message: 'Please input the maximum size!' }]}
                    style={{ margin: 0 }}
                  >
                    <InputNumber min={1} placeholder="Max" style={{ width: '100%' }} />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                name="location"
                label="Location"
                rules={[{ required: true, message: "Please provide the location of your target company" }]}
              >
                <Select
                  placeholder="Select location"
                  mode="tags"
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={countries_options}
                />
              </Form.Item>
              <Form.Item
                name="industry"
                label="Industry"
                rules={[{ required: true, message: "Please provide the industry of your target company" }]}
              >
                <Select
                  placeholder="Select industry"
                  mode="multiple"
                  allowClear
                  filterOption={filterOption}
                  options={industries_options}
                />
              </Form.Item>
              <Form.Item
                name="contactPreference"
                label="Contact Preference"
                rules={[{ required: true, message: "Please provide your contact preferences!" }]}
              >
                <Select
                  mode="tags"
                  allowClear
                  options={decisionMakers}
                  placeholder="Enter contact preferences (e.g., CEO, CTO)"
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
  
        {/* Right Column: Campaign List */}
        <Col span={10}>
          <div
            style={{
              height: '100%',
              border: '1px solid #f0f0f0',
              borderRadius: 8,
              padding: 8,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              maxHeight: '450px',
              backgroundColor: '#fff',
            }}
          >
            <h3 style={{ textAlign: 'center', marginBottom: 16 }}>Available Campaigns</h3>
            <Divider />
            <div style={{ overflowY: 'auto', flex: 1 }}>
              <List
                dataSource={loading ? [] : campaigns}
                loading={loading}
                renderItem={(campaign) => (
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        onClick={() => handleCampaignSelect(campaign)}
                      >
                        Select
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={campaign.name} />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
  
};

export default AddTrackingModal;