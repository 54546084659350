import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, message, Table, Tooltip, Modal, InputNumber, Typography, Tag, Space, Popconfirm, Statistic } from 'antd';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

import DeleteWithModal from './DeleteModal';
import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

import './LeadsPage.css';  // Assume we are using a CSS file for styles

const { Text } = Typography;

const TasksPage = () => {
  const { sessions, setSessions, getSystemSessions, updateSession, cleanupSessions, changeAnalysisTask } = useDocumentPageHook();
  const { dailyGenerationQuota, generationCredit, fetchUserProfile } = useProfilePageHook();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [newQuota, setNewQuota] = useState(0);
  
  const handleOpenModal = (record) => {
    setCurrentRecord(record);
    setNewQuota(record.daily_generation_quota);
    setIsModalVisible(true);
  };
  
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setCurrentRecord(null);
    setNewQuota(0);
  };
  
  const handleQuotaUpdate = async (session_id) => {
    if (session_id) {
      await updateSession(session_id, newQuota)
      fetchSessions();
      handleCloseModal()
    }
  };
  

  const navigate = useNavigate();

  const fetchSessions = async () => {
    await fetchUserProfile();
    const data = await getSystemSessions();
    if (data) {
      setSessions(data);
    } else {
      message.error('Failed to load sessions. Try again later');
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const getTagColor = (state) => {
    switch (state) {
      case 'RUNNING':
      case 'PAUSED':
        return 'yellow';
      case 'COMPLETED':
        return 'green';
      case 'DEFAULT':
      case 'FAILED':
      case 'ABORTED':
        return 'red';
      default:
        return 'blue';
    }
  };

  const handleDelete = async (session_id) => {
    try {
      await cleanupSessions(session_id);  // Call the cleanup session function
      message.success('Session deleted successfully');
      fetchSessions();  // Refresh the sessions list
    } catch (error) {
      message.error('Failed to delete session. Please try again later.');
    }
  };

  const handleChangeStatus = async (session_id, state) => {
    try {
      if (state == 'PAUSED') {
          await changeAnalysisTask(session_id, 'ABORT');  // Call the cleanup session function
      }
      else if (state == 'ABORTED') {
        await changeAnalysisTask(session_id, 'RESUME');  // Call the cleanup session function
      }
      fetchSessions();  // Refresh the sessions list
    } catch (error) {
      message.error('Failed to delete session. Please try again later.');
    }
  };  

  const columns = [
    {
      title: 'Session Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Daily Progress',
      dataIndex: 'url_details',
      key: 'url_count',
      render: (_, record) => {
        const percentage = (record.analyzed_urls / record.total_urls_to_process) * 100;
        const progress = record.daily_generation_quota - record.daily_relevant_research_quota;
    
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              cursor: 'pointer', // Pointer cursor for clickability
              color: '#1890ff', // Ant Design blue
            }}
            onClick={() => handleOpenModal(record)} // Open the modal when clicked
          >
          <Text
            style={{
              color: '#1890ff', // Ant Design's default blue for links
              textDecoration: 'underline', // Underline to enhance the clickable look
            }}
            onClick={() => handleOpenModal(record)} // Open modal on click
          >
            {progress} / {record.daily_generation_quota}
          </Text>

          </div>
        );
      },
      width: 140,
    },
       
    {
      title: 'Automation',
      dataIndex: 'is_automated_session',
      key: 'is_automated_session',
      render: (_, record) => (
        <span>
          {record.is_automated_session ? (
            <Tag color="green">Enabled</Tag>
          ) : (
            <Tag color="red">Disabled</Tag>
          )}{' '}
          <Tooltip title="If enabled, this session will retrigger at 12:00AM PST daily" >
            <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', marginLeft:0 }} />
          </Tooltip>
        </span>
      ),
      width: 150,
    }, 
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (_, record) => (
        <Tag color={getTagColor(record.session_state)}>
          {record.session_state === "DEFAULT" ? "CONFIGURING" : record.session_state}
        </Tag>
      ),
    },    
    {
      title: 'Date',
      dataIndex: 'upload_date',
      key: 'upload_date',
      render: date => new Date(date).toLocaleDateString('en-GB'),  // Format to 'dd/mm/yyyy'
    },    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
        <Button onClick={() => navigate(`/campaign/tasks/${record.session_id}`, { state: { session_id: record.session_id } })}>
          Details
        </Button>
        <DeleteWithModal record={record} handleDelete={handleDelete} />
        <Button
          onClick={() => handleChangeStatus(record.session_id, record.session_state)}
          disabled={record.session_state !== "PAUSED" && record.session_state !== "ABORTED"}
          style={{width:120}}
          danger
        >
          {record.session_state === "PAUSED" 
            ? "Stop Task" 
            : record.session_state === "ABORTED" 
            ? "Resume Task" 
            : "Stop Task"}
        </Button>
      </Space>
      ),
    },
  ];

  return (
    <div className="my-leads-page">
      <div className="upload-section">
        <div className="upload-content" style={{ width: '100%' }}>
          {/* Left side with icon and text */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/upload_icon.png" alt="Upload icon" className="upload-icon" />
            <div className="upload-text">
              <h3>Initiate Lead Analysis</h3>
              <p>Go to setup page to initiate the task. The results will be shown here.</p>
            </div>
          </div>
  
          {/* Right side with statistics */}
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}> 
            <Statistic 
              title={
                <span>
                  Generation Credit 
                  <Tooltip title="When generation credit becomes 0, daily automation for lead generation will stop.">
                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                  </Tooltip>
                </span>
              } 
              value={generationCredit} 
            />
          </div>
        </div>
      </div>
  
      <div className="gen-content-section">
        <Table
          columns={columns}
          dataSource={sessions}
          rowKey="session_id"
          pagination={{ pageSize: 50 }}
        />
      </div>
  
      {/* Quota Modification Modal */}
      <Modal
        title={`Modify Daily Quota for ${currentRecord?.name}`}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            onClick={() => handleQuotaUpdate(currentRecord.session_id)}
            disabled={currentRecord?.session_state !== 'PAUSED'}
          >
            Update Quota
          </Button>,
        ]}
        width={350}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Text>Set a new daily quota for this session:</Text>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
            <Button
              icon={<MinusOutlined />}
              onClick={() => setNewQuota(newQuota - 1)} // Ensure minimum value is 1
              disabled={newQuota <= 1}
              style={{ marginRight: 8 }}
            />
            <InputNumber
              min={1}
              max={dailyGenerationQuota}
              value={newQuota}
              readOnly // Prevent typing
              style={{ margin: '0 8px', width: '100px', pointerEvents: 'none', backgroundColor: '#f5f5f5' }} // Disable pointer events for typing
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() => setNewQuota(newQuota + 1) } // Ensure maximum value is respected
              disabled={newQuota >= dailyGenerationQuota}
            />
          </div>
        </div>

        {currentRecord?.session_state !== 'PAUSED' && (
          <Text type="warning" style={{ marginTop: 16, display: 'block' }}>
            You can only update the quota when the session state is PAUSED.
          </Text>
        )}
      </Modal>
    </div>
  );
  
};

export default TasksPage;
