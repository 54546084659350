import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Space, Tag, Image, Collapse, message, Modal, Row, Col, Divider, Typography, Tooltip, Drawer, Table, DescriptionItem } from 'antd';
import { InfoCircleOutlined, LinkedinOutlined } from '@ant-design/icons';


const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

const TrackingDetailDrawer = ({ visible, onClose, record }) => {
    const navigate = useNavigate();

  if (!record) return;
  const lead = record.company_data;
  const contactPerson = lead?.contact_people[0] || [];

  const DescriptionItem = ({ title, content }) => (
    <div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginTop: 0 }}>
        <Text strong style={{ marginRight: 8 }}>{title}:</Text>     
    </div>
    <Space>
    {content}
    </Space>       
    </div>    
  ); 
  
  const columns = [
    {
      title: 'Contact',
      dataIndex: 'logo_url',
      key: 'logo_url',
      render: (text, record) => (
        <Space>
          <Image
            width={35}
            height={35}
            src={record.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="profile"
          />
          <span>{record.name || 'N/A'}</span>
        </Space>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'LinkedIn',
      dataIndex: 'url',
      key: 'url',
      render: url => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <LinkedinOutlined style={{ fontSize: '20px', color: '#0077b5' }} />
        </a>
      )
    }
  ];  

  const logo = (
    <Row align="middle" justify="space-between" style={{ margin: 0, padding: 0 }}>
    <Col style={{marginLeft:0}}>
        <Image
        width={35}
        height={35}
        src={lead.company_logo ? `data:image/jpeg;base64,${lead.company_logo}`: (lead?.company_logo_url || '/person_icon.png')}
        style={{ borderRadius: '50%' }}
        alt="logo"
        />
        <Title level={5} style={{ margin: '0 0 0 10px', display: 'inline-block' }}>
        {lead.company_name || 'N/A'}
        </Title>
    </Col>
    </Row>    
  )

  return (
    <Drawer
      title={logo}
      placement="right"
      onClose={onClose}
      visible={visible}
      mask={false}
      width={400}
    >      
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
            <Paragraph><strong>Domain:</strong> <div></div><a href={lead.company_website_url} target="_blank" rel="noopener noreferrer">{lead.company_website_url}</a></Paragraph>
            <Paragraph><strong>Linkedin:</strong><div></div> <a href={lead.company_linkedin_url} target="_blank" rel="noopener noreferrer">{lead.company_linkedin_url}</a></Paragraph>

            <Paragraph><strong>Location:</strong> <div></div>{lead.location || 'NA'}</Paragraph>
            <Paragraph><strong>Industry:</strong> <div></div>{lead.company_industry}</Paragraph>
            <Paragraph><strong>Size:</strong> <div></div><Space>{lead.company_size} employees</Space></Paragraph>
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
              <strong>Description:</strong>
              <Space>{lead.company_description}</Space>
            </Paragraph>          
                 
                 
        </div>
        <Divider style={{margin:5}} />

      </Space>
      {/* <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Row align="middle" justify="center" style={{ margin: 0, padding: 0 }}>
            <Col offset={0}>
              <Image
                width={35}
                height={35}
                src={contactPerson?.logo_url || '/person_icon.png'}
                style={{ borderRadius: '50%' }}
                alt="logo"
              />
            </Col>
            <Col offset={0} style={{marginLeft: 10}}>
              <Title level={5} style={{ margin: 0 }}>
                {contactPerson?.name || 'N/A'}
              </Title>
            </Col>
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col span={15}>
                <DescriptionItem title="Title" content={contactPerson?.title || 'N/A'} />
            </Col>
            <Col span={9}>
                <DescriptionItem title="Linkedin" content={<a href={contactPerson?.url} target="_blank" rel="noopener noreferrer"><LinkedinOutlined style={{ fontSize: '25px', color: '#0077b5'}} /></a>} />
            </Col>            
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col span={15}>
                <DescriptionItem title="Email" content={contactPerson?.email_address || 'N/A'} />
            </Col>
            <Col span={9}>
                <DescriptionItem title="Phone" content={contactPerson?.phone_number || 'N/A'} />

            </Col>            
          </Row>                 
        </div>
        <Divider style={{margin:5}} />

        <div>         
        </div>
      </Space>       */}

    </Drawer>
  );
};

export default TrackingDetailDrawer;
