import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const DeleteWithModal = ({ record, handleDelete }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    handleDelete(record.session_id);  // Trigger the delete function for the specific record
    setIsModalVisible(false);  // Close the modal
  };

  const handleCancel = () => {
    setIsModalVisible(false);  // Close the modal without deleting
  };

  return (
    <>
      <Button danger disabled={record.session_state === 'RUNNING' || record.session_state === 'DEFAULT'} onClick={showModal}>
        Delete
      </Button>
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete "{record.name}"?</p>  {/* Dynamically show the session name */}
      </Modal>
    </>
  );
};

export default DeleteWithModal;
